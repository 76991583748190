import "core-js/modules/es.array.push.js";
import Headers from "@/components/headers";
import Tabbar from "@/components/tabbar";
import { listNavId } from "@/api";
export default {
  data() {
    return {
      classId: 1,
      list: [],
      imgs: [require("../../assets/projects/class1.png"), require("../../assets/projects/class2.png"), require("../../assets/projects/class3.png")]
    };
  },
  mounted() {
    this.classId = this.headIndex;
    if (this.classId !== 0) {
      this.getList();
    } else {
      this.$store.dispatch("setHeadIndex", 1);
    }
  },
  methods: {
    getList() {
      listNavId(this.classId).then(res => {
        this.list = res.data;
      });
    },
    goDetails(value) {
      this.$router.push({
        path: '/project-details',
        query: {
          id: value.id,
          title: value.title
        }
      });
    }
  },
  computed: {
    headIndex() {
      return this.$store.state.headIndex;
    }
  },
  watch: {
    headIndex(value) {
      if (value === 0) return;
      this.classId = value;
      this.getList();
    }
  },
  components: {
    Tabbar,
    Headers
  }
};